import { Component, Input, ViewChild, NgZone, OnInit, OnDestroy } from '@angular/core';
import { Capacitor, PluginListenerHandle } from '@capacitor/core';
import { Directory, Filesystem } from '@capacitor/filesystem';
import { FileOpener } from '@capawesome-team/capacitor-file-opener';
import { PermissionManager } from '../permission-manager/permission-manager.component';
import { AlertService } from 'src/app/providers/alert/alert.service';
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { TranslateService } from '@ngx-translate/core';

/**
 * Component responsible for managing file downloads, displaying progress, and handling file actions such as opening or deleting files.
 *
 * ### Available modes:
 * The `file-downloader` component distinguishes between menu mode and hyperlink mode:
 * 
 * - **Menu Mode:** On native platforms, it displays a menu with options to download, open, or delete files. On web platforms, the file is downloaded directly.
 * - **Hyperlink Mode:** On native platforms, the file is directly downloaded and opened in the appropriate app. On web platforms, the browser decides whether to display or download the file.
 *
 * ### Input Parameters:
 *
 * - **downloadUrl (required):**  
 *   The URL of the file to be downloaded. This parameter is mandatory.
 * 
 * - **openAfterDownload (optional, default: `false`):**  
 *   Determines whether the file should be opened automatically after download. 
 *
 * - **clearFileName (optional, default: `null`):**  
 *   The custom name to use for the downloaded file. If not specified, the original file name will be used.
 * 
 * - **faIcon (optional, default: `null`):**  
 *   FontAwesome icon definition to display alongside the download button. If not specified, no icon is displayed.
 * 
 * - **showAsHyperlink (optional, default: `false`):**  
 *   Determines whether the component should be displayed as a clickable hyperlink instead of a button. In hyperlink mode, the file is directly downloaded or opened.
 * 
 */
@Component({
  selector: 'file-downloader',
  templateUrl: './file-downloader.component.html',
  styleUrls: ['./file-downloader.component.scss'],
})
export class FileDownloader implements OnInit, OnDestroy {
  /** URL of the file to be downloaded. */
  @Input() downloadUrl: string;

  /** Whether to open the file automatically after downloading. Default is `false` */
  @Input() openAfterDownload: boolean = false;

  /** Optional clear file name to use for the downloaded file. If null, the original file name will be used. */
  @Input() clearFileName?: string = null;

  /** FontAwesome icon definition to display in hyperlink mode. */
  @Input() faIcon?: IconDefinition = null;

  /** Whether to display the component as a hyperlink. Default is `false`. */
  @Input() showAsHyperlink: boolean = false;

  /** Reference to the permission manager component for handling required storage permissions. */
  @ViewChild("permissionOnboardingRef") permissionManager: PermissionManager;

  /** Tracks the download status. `true` if a file is being downloaded. */
  isDownloading: boolean = false;

  /** Indicates whether the app is running on a native platform (iOS or Android). */
  isNative = Capacitor.isNativePlatform();

  /** The menu icon to be displayed in the UI depending on the platform. */
  menuIcon: string;

  /** Tracks the state of the options menu (open or closed). */
  menuIsOpen: boolean = false;

  /** The download progress as a percentage string. */
  downloadProgress: string = "0";

  /** Array of available options for the action menu. */
  menuOptions: any[] = [];

  /** Handle for the download progress listener only on native platforms. */
  private downloadListenerHandle: PluginListenerHandle;

  /** XMLHttpRequest instance for managing web-based downloads. */
  private xhrDownloadRequest: XMLHttpRequest;

  /** File path where the downloaded file will be saved on native platforms. */
  private filePath: string;

  constructor(
    private alertService: AlertService,
    private translateService: TranslateService,
    private ngZone: NgZone
  ) { }

  /**
   * Initializes the component, sets the appropriate menu icon, and configures native file download progress handling.
   */
  async ngOnInit(): Promise<void> {
    this.setMenuIcon();

    if (this.isNative) {
      this.setFilePath();
      this.addDownloadProgressListener();
    }
  }

  /**
   * Cleans up resources and removes listeners when the component is destroyed.
   */
  ngOnDestroy(): void {
    this.removeDownloadProgressListener();
  }

  /**
   * Handles menu button clicks, opening the menu on native platforms or triggering a file download on web platforms via XHR request.
   */
  menuButtonClicked(): void {
    if (this.isNative) {
      this.openMenu();
    } else {
      this.downloadFile();
    }
  }

  /**
   * Handles hyperlink clicks, opening the file if it already exists, or downloading it if not.
   */
  async anchorClicked(): Promise<void> {
    if (await this.isFileExisting()) {
      this.openFile();
    } else {
      this.downloadFile();

      if (!this.openAfterDownload) {
        this.openFile();
      }
    }
  }

  /**
   * Handles the selection of an option from the menu (action sheet), performing actions such as downloading, opening, or deleting a file.
   * 
   * @param event Event containing details about the selected menu option.
   */
  handleSelectedMenuOption(event): void {
    this.menuIsOpen = false;

    switch (event.detail?.data?.action) {
      case "download": {
        this.downloadFile();
        break;
      }

      case "open": {
        this.openFile();
        break;
      }

      case "delete": {
        this.deleteFile();
        break;
      }
    }
  }

  /**
   * Aborts an ongoing web-based download.
   */
  abortWebDownload(): void {
    this.xhrDownloadRequest?.abort();
    this.isDownloading = false;
    this.downloadProgress = "0";
    this.alertService.presentToast("SUCCESS.SUCCESS_MEDIA_DOWNLOAD_ABORTED");
  }

  /**
   * Initiates the file download process based on the platform (native or web).
   */
  private downloadFile(): void {
    if (this.isNative) {
      this.downloadNative();
    } else {
      this.webDownload();
    }
  }

  /**
   * Downloads the file on a native platform using Capacitor's Filesystem API.
   * Ensures that the appropriate storage permissions are granted before proceeding.
   */
  private downloadNative(): void {
    this.permissionManager.checkAndMayAskForNativePermissions({ singlePermissions: ["storage"] })
      .then(permissionStatus => {
        if (permissionStatus.storage) {
          this.isDownloading = true;
          Filesystem.downloadFile({
            method: "GET",
            url: this.downloadUrl,
            path: this.filePath,
            directory: Directory.Documents,
            recursive: true,
            progress: true
          })
            .then(_ => {
              this.downloadProgress = "100";
              this.isDownloading = false;
              this.alertService.presentToast("SUCCESS.SUCCESS_MEDIA_DOWNLOADED");
              if (this.openAfterDownload) {
                this.openFile();
              }
            })
            .catch(_ => {
              this.isDownloading = false;
              this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_DOWNLOAD");
            });
        } else {
          this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_MISSING_FILESYSTEM_PERMISSION");
        }
      })
  }

  /**
   * Downloads the file on web platforms using an XMLHttpRequest.
   */
  private webDownload(): void {
    this.xhrDownloadRequest = new XMLHttpRequest();
    this.xhrDownloadRequest.open('GET', this.downloadUrl, true);
    this.xhrDownloadRequest.responseType = 'blob';

    this.xhrDownloadRequest.onprogress = (event) => {
      if (event.lengthComputable) {
        this.downloadProgress = ((event.loaded / event.total) * 100).toFixed(0);
      }
    };

    this.xhrDownloadRequest.onload = () => {
      if (this.xhrDownloadRequest.status === 200) {
        const blob = this.xhrDownloadRequest.response;

        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = this.clearFileName;
        link.click();
        this.isDownloading = false
      } else {
        this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_DOWNLOAD");
      }
    };

    this.xhrDownloadRequest.onerror = () => {
      this.alertService.showError("ERROR.ERROR", "ERROR.ERROR_DOWNLOAD");
    };

    this.isDownloading = true;
    this.xhrDownloadRequest.send();
  }

  /**
   * Opens the downloaded file using the Capacitor FileOpener plugin on native platforms.
   * This function can only be used on native platforms!
   */
  private async openFile(): Promise<void> {
    const fileStats = await Filesystem.stat({
      path: this.filePath,
      directory: Directory.Documents
    });
    FileOpener.openFile(
      {
        path: fileStats.uri
      }
    ).catch(_ => {
      this.alertService.presentToast("ERROR.ERROR_NO_APP_TO_OPEN_FILE");
    });
  }

  /**
   * Deletes the downloaded file using the Capacitor Filesystem API on native platforms.
   * This function can only be used on native platforms!
   */
  private async deleteFile(): Promise<void> {
    await Filesystem.deleteFile({
      path: this.filePath,
      directory: Directory.Documents
    });
    this.alertService.presentToast("SUCCESS.SUCCESS_MEDIA_DELETED");
  }

  /**
   * Opens the action menu, displaying options such as downloading, opening, or deleting the file.
   * This function can only be used on native platforms!
   */
  private async openMenu(): Promise<void> {
    this.isFileExisting();
    await this.determineActionSheetOptions();
    this.menuIsOpen = true;
  }

  /**
   * Determines the available options for the action menu based on whether the file already exists.
   * If the file exists, a file removal, open file and cancel option will be displayed.
   * Otherwise a donwload and cancel option will be displayed.
   * This function can only be used on native platforms!
   */
  private async determineActionSheetOptions(): Promise<void> {
    const actionSheetOptions = [];

    if (await this.isFileExisting()) {
      actionSheetOptions.push({
        text: this.translateService.instant("LESSON.MEDIA_DOWNLOAD_OPEN_FILE"),
        data: {
          action: 'open',
        },
      });

      actionSheetOptions.push({
        text: this.translateService.instant("LESSON.MEDIA_DOWNLOAD_DELETE_FILE"),
        role: 'destructive',
        data: {
          action: 'delete',
        },
      });
    } else {
      actionSheetOptions.push({
        text: this.translateService.instant("LESSON.MEDIA_DOWNLOAD_START"),
        data: {
          action: 'download',
        },
      });
    }

    actionSheetOptions.push({
      text: this.translateService.instant("BUTTONS.CANCEL"),
      role: 'cancel',
      data: {
        action: 'cancel',
      }
    })

    this.menuOptions = actionSheetOptions;
  }

  /**
   * Sets the menu icon based on the platform (web, Android, iOS).
   */
  private setMenuIcon(): void {
    switch (Capacitor.getPlatform()) {
      case "web": {
        this.menuIcon = "arrow-down";
        break;
      }

      case "android": {
        this.menuIcon = "ellipsis-vertical";
        break;
      }

      case "ios": {
        this.menuIcon = "ellipsis-horizontal-circle"
        break;
      }
    }
  }

  /**
   * Checks whether the file already exists in the filesystem of the native platform.
   * This function can only be used on native platforms!
   * 
   * @returns Promise that resolves to true if the file exists, false otherwise.
   */
  private async isFileExisting(): Promise<boolean> {
    return await Filesystem.stat({
      path: this.filePath,
      directory: Directory.Documents
    })
      .then(_ => {
        return true;
      })
      .catch(_ => {
        return false;
      });
  }

  /**
   * Sets the file path based on the provided download URL and file name.
   * If the file has the clear file name `awesomeFile.mp4` and the hashed file name `9f327416c325a0052ad856.mp4` the filename will be concatenated with the first eight characters of the hashed file name to ensure human readable and unique filenames.
   * The result of these two file names would be `awesomeFile_9f327416.mp4`.
   * If the clear file name is nullish or an empty string, only the hashed file name will be selected as filename.
   */
  private setFilePath(): void {
    if (!this.downloadUrl) {
      return;
    }

    const lastSlashIndex = this.downloadUrl.lastIndexOf("/");
    if (lastSlashIndex === -1) {
      // URL invalid -> Makes the component invisible
      this.downloadUrl = null;
      return;
    }

    // "http://example.com/path1/path2/9f327416c325a0052ad856.mp4" -> hashedFileName = "9f327416c325a0052ad856.mp4"
    const hashedFileName = this.downloadUrl.slice(lastSlashIndex + 1);

    if (!this.clearFileName) {
      this.filePath = hashedFileName;
      return;
    }

    // "9f327416c325a0052ad856.mp4" -> fileExtension = ".mp4"
    const fileExtensionIndex = hashedFileName.lastIndexOf(".");
    let fileExtension = "";
    if (fileExtensionIndex !== -1) {
      fileExtension = hashedFileName.slice(fileExtensionIndex);
    }

    // "awesomeFile.mp4" -> clearFileNameWithoutExtension = "awesomeFile"
    let clearFileNameExtensionIndex = this.clearFileName.lastIndexOf(".");
    let clearFileNameWithoutExtension = this.clearFileName;
    if (clearFileNameExtensionIndex !== -1) {
      clearFileNameWithoutExtension = this.clearFileName.slice(0, clearFileNameExtensionIndex);
    }

    // "9f327416c325a0052ad856.mp4" -> first8CharacterOfHash = "9f327416"
    const first8CharacterOfHash = hashedFileName.slice(0,8);

    this.filePath = `${clearFileNameWithoutExtension}_${first8CharacterOfHash}${fileExtension}`;
  }

  /**
   * Adds a listener to track the download progress using Capacitor's Filesystem API.
   * This function can only be used on native platforms!
   */
  private async addDownloadProgressListener(): Promise<void> {
    this.downloadListenerHandle = await Filesystem.addListener('progress', (progressStatus) => {
      this.ngZone.run(() => {
        const percentage = (progressStatus.bytes / Math.max(1, progressStatus.contentLength)) * 100;
        this.downloadProgress = percentage.toFixed(0);
      });
    });
  }

  /**
   * Removes the download progress listener when it's no longer needed.
   * This function can only be used on native platforms!
   */
  private removeDownloadProgressListener(): void {
    this.downloadListenerHandle?.remove();
  }
}
