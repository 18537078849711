import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Platform } from "@ionic/angular";
import { Answer } from "../../models/Answer";
import { LocalStorageService } from "../local-storage/local-storage.service";
import { BACKEND_URL } from "../../../environments/environment";
import { HelperService } from "../helper/helper.service";
import { SensoryInformation } from "src/app/models/SensoryInformation";
import { Capacitor } from "@capacitor/core";

@Injectable({
  providedIn: "root",
})
export class RequestProviderService {

  private clientName: "ionic-native-ios" | "ionic-native-android" | "ionic-native" | "ionic-webapp";
  private clientDeviceName: "native" | "inapp" | "webapp";

  constructor(
    private http: HttpClient,
    private platform: Platform,
    private localStorage: LocalStorageService,
    private helperService: HelperService,
  ) { 
    const isNative = Capacitor.isNativePlatform();
    if (isNative) {
      if (this.platform.is("ios")) {
        this.clientName = "ionic-native-ios";
      } else if (this.platform.is("android")) {
        this.clientName = "ionic-native-android";
      } else {
        this.clientName = "ionic-native";
      }
    } else {
      this.clientName = "ionic-webapp";
    }
    if (isNative) {
      this.clientDeviceName = "native";
    } else if (this.localStorage.getIsInAppBrowser()) {
      this.clientDeviceName = "inapp";
    } else {
      this.clientDeviceName = "webapp";
    }
  }

  getMyInstances() {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams()
      .append("limit", "0")
      .append("pendingInvitation", "false");
    return this.http.get(BACKEND_URL + "/api/v1/my/interventions/instances", {
      headers,
      params,
      observe: "response",
    });
  }

  getInstance(instanceId: number) {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.get(
      BACKEND_URL + "/api/v1/interventions/instances/" + instanceId,
      {
        headers,
        observe: "response",
      },
    );
  }

  getDiaryInstance(instanceId: number) {
    const headers = new HttpHeaders()
      .append("accept-language", this.localStorage.getAppLanguage())
      .append("id", instanceId.toString());
    const params = new HttpParams()
      .append("include", "schedules")
      .append("id", instanceId);
    return this.http.get(BACKEND_URL + "/api/v1/my/diaries/instances", {
      headers,
      params,
      observe: "response",
    });
  }

  getAnswersheetsOfInstance(instanceId: number) {
    let headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams().append("limit", "0");
    return this.http.get(
      BACKEND_URL +
      "/api/v1/interventions/instances/" +
      instanceId +
      "/answersheets",
      {
        headers: headers,
        params,
        observe: "response",
      },
    );
  }

  getLessonsOfIntervention(interventionId: number) {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams().append("limit", "0");
    return this.http.get(
      BACKEND_URL +
      "/api/v1/interventions/" +
      interventionId +
      "/questionnaires",
      {
        headers,
        params,
        observe: "response",
      },
    );
  }

  getMyLessonsWithSkills() {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams()
      .append("limit", "0")
      .append("skills!", "[]");
    return this.http.get(BACKEND_URL + "/api/v1/my/questionnaires", {
      headers,
      params,
      observe: "response",
    });
  }

  getMyInterventions() {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams().append("limit", "0");
    return this.http.get(BACKEND_URL + "/api/v1/my/interventions", {
      headers,
      params,
      observe: "response",
    });
  }

  getIntervention(interventionId: number) {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.get(
      BACKEND_URL + "/api/v1/interventions/" + interventionId,
      {
        headers,
        observe: "response",
      },
    );
  }

  getInstanceReport(instanceId: number) {
    const headers = new HttpHeaders()
      .append("accept-language", this.localStorage.getAppLanguage())
      .append("Content-Type", "*/*")
      .append("Accept", "*/*");
    return this.http.get(
      BACKEND_URL + "/api/v1/interventions/instances/" + instanceId + "/report",
      {
        headers,
        observe: "response",
        responseType: "blob" as "json",
      },
    );
  }

  getAnswersheetReport(answersheetId: number, timezone: string) {
    const headers = new HttpHeaders()
      .append("accept-language", this.localStorage.getAppLanguage())
      .append("Content-Type", "*/*")
      .append("Accept", "*/*");
    const params = new HttpParams().append("timezone", timezone);
    return this.http.get(
      BACKEND_URL + "/api/v1/answersheets/" + answersheetId + "/pdf/report",
      {
        headers,
        params,
        observe: "response",
        responseType: "blob" as "json",
      },
    );
  }

  getLessonDetails(lessonId: number) {
    let headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.get(BACKEND_URL + "/api/v1/questionnaires/" + lessonId, {
      headers: headers,
      observe: "response",
    });
  }

  getLessonElements(lessonId: number) {
    let headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.get(
      BACKEND_URL + "/api/v1/questionnaires/" + lessonId + "/structure",
      {
        headers: headers,
        observe: "response",
      },
    );
  }

  getMediaComponent(url: string) {
    return this.http.get(url, {
      observe: "response",
      responseType: "blob",
    });
  }

  //generate an activity of opening a lesson
  setLessonOpened(instanceID: number, questionnaireID: number) {
    return this.http.post(
      BACKEND_URL +
      "/api/v1/interventions/instances/" +
      instanceID +
      "/questionnaires/" +
      questionnaireID +
      "/activities",
      {},
      {
        observe: "response",
      },
    );
  }

  //generate an activity of opening a diary
  setDiaryOpened(diaryInstancId: number) {
    return this.http.post(
      BACKEND_URL + "/api/v1/diaries/instances/" + diaryInstancId + "/start",
      {},
      {
        observe: "response",
      },
    );
  }

  //answersheet submitted, progress updated automatically
  submitAnswersheet(
    lessonId: number,
    instanceId: number,
    answers: Answer[],
    forDiary: boolean,
    finished: boolean,
    collected_at: number,
    buddies_id?: number,
    sensor_data?: SensoryInformation
  ) {
    const instanceKey = forDiary
      ? "diary_instance_id"
      : "intervention_instance_id";
    const json = {
      data: {
        type: "answersheets",
        attributes: {
          [instanceKey]: instanceId,
          locale: this.localStorage.getAppLanguage(),
          answers,
          collected_at,
          buddies_id: buddies_id || undefined,
          sensor_data: sensor_data ?? undefined,
          client: {
            name: this.clientName,
            device: this.clientDeviceName, // @todo put in real device
            os: this.platform.platforms(),
          },
          finished,
          version: "2.0.0",
        },
      },
    };
    const url = `${BACKEND_URL}/api/v1/questionnaires/${lessonId}/answersheets`;
    const headers = new HttpHeaders().append(
      "Content-Type",
      "application/json",
    );
    return this.http.post(url, json, { headers: headers, observe: "response" });
  }

  getAnswersheet(answersheetId: number) {
    let headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.get(
      BACKEND_URL + "/api/v1/answersheets/" + answersheetId,
      {
        headers: headers,
        observe: "response",
      },
    );
  }

  getMyDiaryInstances() {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams()
      .append("include", "schedules")
      .append("limit", "0")
      .append("pendingInvitation", "false");
    return this.http.get(BACKEND_URL + "/api/v1/my/diaries/instances", {
      headers: headers,
      params: params,
      observe: "response",
    });
  }

  getMyAnswersheets() {
    let params = new HttpParams()
      .append("limit", "0")
      .append("pendingInvitation", "false");
    return this.http.get(BACKEND_URL + "/api/v1/my/answersheets", {
      params: params,
      observe: "response",
    });
  }

  getUnlockedDiariesOfInstance(instanceId: number) {
    const url = `${BACKEND_URL}/api/v1/interventions/instances/${instanceId}/unlocked/diaries`;
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams()
      .append("include", "schedules")
      .append("limit", "0");
    return this.http.get(url, {
      headers: headers,
      params: params,
      observe: "response",
    });
  }

  unlockConditionalQuestionnaire(
    instanceId: number,
    sourceQuestionnaire: number,
    questionnaires: Array<number>,
  ) {
    return new Promise((resolve, reject) => {
      let headers = new HttpHeaders();
      this.http
        .patch(
          BACKEND_URL +
          "/api/v1/interventions/instances/" +
          instanceId +
          "/condition/unlock",
          {
            data: {
              type: "intervention_instance",
              attributes: {
                questionnaire_id: sourceQuestionnaire,
                progress: {
                  unlock_questionnaires: questionnaires,
                },
              },
            },
          },
          {
            headers: headers,
            observe: "response",
          },
        )
        .subscribe({
          next: (response) => {
            resolve(response);
          },
          error: (error) => reject(error),
        });
    });
  }

  getConditionalLessonsToUnlock(instanceId: number, lessonId: number) {
    return this.http.get(
      BACKEND_URL +
      "/api/v1/interventions/instances/" +
      instanceId +
      "/questionnaires/" +
      lessonId +
      "/unlock",
      {
        observe: "response",
      },
    );
  }

  getDiaryDetails(diaryId: number) {
    let headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.get(BACKEND_URL + "/api/v1/diaries/" + diaryId, {
      headers: headers,
      observe: "response",
    });
  }

  //do not change sort -id
  // it is used in diary page showUnlock case 2
  getAnswersheetsOfDiaryInstance(diaryInstanceId: number) {
    let headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    let params = new HttpParams().append("limit", "0").append("sort", "-id");
    return this.http.get(
      BACKEND_URL +
      "/api/v1/diaries/instances/" +
      diaryInstanceId +
      "/answersheets",
      {
        headers: headers,
        params: params,
        observe: "response",
      },
    );
  }

  //returns userid of buddy & id of buddy instance, if answersheet is created for the diary which is used for the buddy feature
  //meaning current diary instance id is the same as diary_instance_id in buddy diary_instance_ids array for this user
  getBuddyFeatureIds(
    currentUserId: number,
    instanceID: number,
  ): Promise<{ userIdOfBuddy: number; buddyInstanceId: number } | null> {
    return new Promise((resolve, reject) => {
      const headers = new HttpHeaders().append(
        "Content-Type",
        "application/json",
      );
      this.http
        .get(BACKEND_URL + "/api/v1/my/buddies", {
          headers,
          observe: "response",
        })
        .subscribe({
          next: (responseData) => {
            if (!responseData.body["data"].length) {
              reject("User has no buddy");
              return;
            }

            console.log(currentUserId);
            console.log(instanceID);
            console.log(responseData.body["data"]);

            for (const entry of responseData.body["data"]) {
              //find entry for this diary instance
              for (const instance of entry.attributes.diary_instances_ids) {
                console.log(instance);
                if (Number(instance.diary_instance_id) === Number(instanceID)) {
                  console.log(entry.attributes.buddies_ids);
                  const buddyUserIds = entry.attributes.buddies_ids.filter(
                    (id: string | number) =>
                      Number(id) != Number(currentUserId),
                  );
                  console.log(buddyUserIds);
                  if (buddyUserIds.length === 1) {
                    console.log(buddyUserIds[0]);
                    resolve({
                      userIdOfBuddy: Number(buddyUserIds[0]),
                      buddyInstanceId: Number(entry.id),
                    });
                  }
                }
              }
            }
            resolve(null);
          },
          error: (error) => reject(error),
        });
    });
  }

  async getBuddyActivities(
    buddyInstanceId: number,
    userIdOfBuddy: number,
  ): Promise<{
    dailyTaskDone: boolean;
    dailyTaskDoneBuddy: boolean;
    reminded: boolean;
    remindedBuddy: boolean;
  }> {
    const currentDate = new Date().toISOString().slice(0, 10) + " 00:00:00";
    console.log(currentDate);
    return new Promise(async (resolve, reject) => {
      const headers = new HttpHeaders().append(
        "Content-Type",
        "application/json",
      );
      const params = new HttpParams().append("limit", "0");
      this.http
        .get(
          BACKEND_URL +
          "/api/v1/my/buddies/" +
          buddyInstanceId +
          "/activities?created_at>" +
          currentDate,
          {
            headers,
            params,
            observe: "response",
          },
        )
        .subscribe({
          next: (responseData) => {
            if (!responseData.body["data"].length) {
              reject();
              return;
            }

            const myEntry = responseData.body["data"].filter(
              (activity) =>
                activity.attributes.user_id === this.localStorage.getUserId(),
            )[0];
            console.log(
              "my id: " + this.localStorage.getUserId() + ", my entry:",
            );
            console.log(myEntry);

            const buddyEntry = responseData.body["data"].filter(
              (activity) => activity.attributes.user_id === userIdOfBuddy,
            )[0];
            console.log("buddy id: " + userIdOfBuddy + ", buddy entry:");
            console.log(buddyEntry);

            const result = {
              dailyTaskDone: true, // the user has just completed the task
              reminded: myEntry.attributes.daily_reminder_timestamps != null, // check if user has sent reminder email
              dailyTaskDoneBuddy:
                buddyEntry != undefined
                  ? buddyEntry.attributes.daily_task_timestamps != null
                  : false, // check if buddy has completed the task
              remindedBuddy:
                buddyEntry != undefined
                  ? buddyEntry.attributes.daily_reminder_timestamps != null
                  : false, // check if buddy has sent reminder email
            };
            console.log(result);
            resolve(result);
          },
          error: (error) => reject(error),
        });
    });
  }

  // function for send the reminder mail to the buddy
  sendBuddyReminder(userIdOfBuddy: number) {
    console.log(userIdOfBuddy);
    const headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .append("accept-language", this.localStorage.getAppLanguage());
    return this.http.post(
      BACKEND_URL + "/api/v1/my/buddies/" + userIdOfBuddy + "/remind",
      {
        headers,
        observe: "response",
      },
    );
  }

  getMySkills() {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams().append("limit", "0");
    return this.http.get(BACKEND_URL + "/api/v1/my/skills", {
      headers,
      params,
      observe: "response",
    });
  }

  getSkill(skillId: number) {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.get(BACKEND_URL + "/api/v1/skills/" + skillId, {
      headers,
      observe: "response",
    });
  }

  getSkillsOfIntervention(interventionId: number) {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.get(
      BACKEND_URL + "/api/v1/interventions/" + interventionId + "/skills",
      {
        headers,
        observe: "response",
      },
    );
  }

  getSkillDescription(skillId: number) {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.get(
      BACKEND_URL + "/api/v1/skills/" + skillId + "/elements",
      {
        headers,
        observe: "response",
      },
    );
  }

  getMyActivities() {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams().append("sort", "-id").append("limit", "0");
    // .append('action', 'QUESTIONNAIRE_STARTED')
    return this.http.get(BACKEND_URL + "/api/v1/my/activities", {
      headers,
      params,
      observe: "response",
    });
  }

  getMyMessages() {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams()
      .append("include", "messages,participants")
      .append("answersheetID", "false")
      .append("limit", "0");
    return this.http.get(BACKEND_URL + "/api/v1/messages/threads", {
      headers,
      params,
      observe: "response",
    });
  }

  getMessageThread(threadID: number) {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams().append("include", "messages,participants");
    return this.http.get(BACKEND_URL + "/api/v1/messages/threads/" + threadID, {
      headers,
      params,
      observe: "response",
    });
  }

  sendMessage(message: string, threadID: number) {
    return this.http.post(
      BACKEND_URL + "/api/v1/messages/threads/" + threadID + "/messages",
      {
        data: {
          type: "messages/messages",
          attributes: {
            body: message,
          },
        },
      },
      {
        observe: "response",
      },
    );
  }

  getMyFeedback() {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const params = new HttpParams()
      .append("include", "messages,participants")
      .append("answersheetID", "true")
      .append("limit", "0");
    return this.http.get(BACKEND_URL + "/api/v1/messages/threads", {
      headers,
      params,
      observe: "response",
    });
  }

  setFeedbackRead(instanceId: number, progressJSON: object) {
    let headers = new HttpHeaders().append("Content-Type", "application/json");
    return this.http.patch(
      BACKEND_URL +
      "/api/v1/interventions/instances/" +
      instanceId +
      "/progress",
      progressJSON,
      {
        headers: headers,
        observe: "response",
      },
    );
  }

  getMyInvitations() {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.get(BACKEND_URL + "/api/v1/my/invitations", {
      headers,
      observe: "response",
    });
  }

  acceptInvitation(invitationToken: string) {
    return this.http.post(
      BACKEND_URL + "/api/v1/studies/invitation",
      {
        data: {
          type: "users",
          attributes: {
            code: invitationToken,
          },
        },
      },
      {
        observe: "response",
      },
    );
  }

  getMyProfile() {
    return this.http.get(BACKEND_URL + "/api/v1/my/profile", {
      observe: "response",
    });
  }

  updateProfile(attributes: object) {
    const url = BACKEND_URL + "/api/v1/my/profile";
    const body = {
      data: {
        type: "users",
        attributes: attributes,
      },
    };
    const options = {
      observe: "response",
    } as const;
    return this.http.patch(url, body, options);
  }

  changePassword(
    currentPassword: string,
    password: string,
    confirmPassword: string,
  ) {
    const headers = new HttpHeaders().append(
      "Content-Type",
      "application/json",
    );
    return this.http.patch(
      BACKEND_URL + "/api/v1/my/profile/password",
      {
        data: {
          type: "users",
          attributes: {
            current_password: currentPassword,
            password: password,
            password_confirmation: confirmPassword,
          },
        },
      },
      {
        headers,
        observe: "response",
      },
    );
  }

  // Sends a reset password instructions email for the user.
  forgotPassword(email: string) {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const body = {
      data: {
        type: "users",
        attributes: {
          email: email,
        },
      },
    };
    return this.http.post(
      BACKEND_URL + "/api/v1/auth/password/reset/instructions",
      body,
      {
        headers,
        observe: "response",
      },
    );
  }

  requestDeleteAccount(password: string, pseudonymized: boolean) {
    const headers = new HttpHeaders()
      .append("Content-Type", "application/json")
      .append("accept-language", this.localStorage.getAppLanguage());
    return this.http.post(
      BACKEND_URL + "/api/v1/my/account/delete/request",
      {
        data: {
          type: "users",
          attributes: {
            pseudonymized: pseudonymized,
            password: password,
          },
        },
      },
      {
        headers,
        observe: "response",
      },
    );
  }

  getDeleteAccountRequest() {
    const headers = new HttpHeaders().append(
      "Content-Type",
      "application/json",
    );
    return this.http.get(BACKEND_URL + "/api/v1/my/account/delete/request", {
      headers,
      observe: "response",
    });
  }

  cancelDeleteAccountRequest() {
    const headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    return this.http.delete(BACKEND_URL + "/api/v1/my/account/delete/request", {
      headers,
      observe: "response",
    });
  }

  getAnnouncements() {
    let headers = new HttpHeaders().append(
      "accept-language",
      this.localStorage.getAppLanguage(),
    );
    const currentTime = this.helperService.getCurrentTimeTruncated();
    return this.http.get(
      BACKEND_URL +
      "/api/v1/announcements?client=app&sort=-start&start<" +
      currentTime +
      "&end>" +
      currentTime,
      {
        headers: headers,
        observe: "response",
      },
    );
  }

  login(credentials: { email: string; password: string }) {
    return this.http.post(
      BACKEND_URL + "/api/v1/auth/login",
      {
        data: {
          type: "users",
          attributes: {
            email: credentials.email,
            password: credentials.password,
          },
        },
      },
      {
        observe: "response",
        withCredentials: true,
      },
    );
  }

  refreshToken() {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          BACKEND_URL + "/api/v1/auth/refresh",
          {},
          {
            observe: "response",
          },
        )
        .subscribe({
          next: (response) => {
            resolve(response);
          },
          error: (error) => {
            reject(error);
          },
        });
    });
  }

  logout() {
    const headers = new HttpHeaders().append(
      "Authorization",
      "Bearer " + this.localStorage.getToken(),
    );
    return this.http.delete(BACKEND_URL + "/api/v1/auth/logout", {
      headers,
      observe: "response",
    });
  }

  // Send server firebase token to pinpoint the device and send notifications
  registerDeviceToken(token: string) {
    const headers = new HttpHeaders().append(
      "Content-Type",
      "application/json",
    );
    return this.http.post(
      BACKEND_URL + "/api/v1/messages/devicetokens",
      {
        data: {
          type: "messages/devicetokens",
          attributes: {
            firebase_token: token,
          },
        },
      },
      {
        headers,
        observe: "response",
      },
    );
  }

  deleteDeviceToken(id: number) {
    return this.http.delete(
      BACKEND_URL + "/api/v1/messages/devicetokens/" + id,
      {
        observe: "response",
      },
    );
  }
}
