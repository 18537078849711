import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";
import { Thread } from "../../models/Thread";
import {
  BACKEND_URL,
  BACKEND_URL_MEDIA,
} from "../../../environments/environment";
import { Answersheet } from "src/app/models/Answersheet";

const LANGUAGES = ["de", "en", "fr", "es", "ca", "ro"] as const;
type Language = (typeof LANGUAGES)[number];

@Injectable({
  providedIn: "root",
})
export class HelperService {
  private previousUrl: string;
  private currentUrl: string;

  constructor(
    private translateService: TranslateService,
    private router: Router,
  ) {
    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      }
    });
  }

  public formatDate(
    unformattedDate: number,
    locale: Language,
    showDateTime: boolean = true,
  ): string {
    const date = new Date(unformattedDate);
    let dateString: string;
    if (!showDateTime) {
      const dateOptions = {
        weekday: "long",
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      } as const;
      dateString = new Intl.DateTimeFormat(locale, dateOptions).format(date);
      if (locale === "de") {
        dateString += " Uhr";
      }
    } else {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(today.getDate() - 1);
      if (date.toDateString() === today.toDateString()) {
        dateString = this.translateService.instant("CHAT_FEEDBACK.TODAY");
      } else if (date.toDateString() === yesterday.toDateString()) {
        dateString = this.translateService.instant("CHAT_FEEDBACK.YESTERDAY");
      } else {
        const dateOptions = {
          day: "2-digit",
          month: "2-digit",
          year: "numeric",
        } as const;
        dateString = new Intl.DateTimeFormat(locale, dateOptions).format(date);
      }
      const timeOptions = { hour: "2-digit", minute: "2-digit" } as const;
      const time = new Intl.DateTimeFormat(locale, timeOptions).format(date);
      dateString += ", " + time;
      if (locale === "de") {
        dateString += " Uhr";
      }
    }
    return dateString;
  }

  public getPicture(picture: string, studyId: number) {
    if (picture) {
      return `${BACKEND_URL_MEDIA}/${studyId}/${picture}`;
    } else {
      return "../../../assets/icon/favicon.png";
    }
  }

  public getUserPicture(userId: number) {
    return BACKEND_URL + "/uploads/users/" + userId;
  }

  public getPreviousUrl() {
    return this.previousUrl;
  }

  public getStateText(instanceState: string, details: boolean) {
    if (instanceState === "awaiting_next_questionnaire") {
      return this.translateService.instant("INSTANCE.WAITING_LESSON_UNLOCK");
    } else if (instanceState === "feedback_required") {
      if (details) {
        return this.translateService.instant(
          "INSTANCE.INSTANCE_FEEDBACK_REQUIRED",
        );
      } else {
        return this.translateService.instant(
          "INSTANCE.WAITING_LESSON_FEEDBACK",
        );
      }
    } else if (instanceState === "feedback_provided") {
      return this.translateService.instant("INSTANCE.LOOK_AT_FEEDBACK");
    } else if (instanceState === "paused") {
      return this.translateService.instant("INSTANCE.INSTANCE_PAUSED");
    } else if (instanceState === "canceled") {
      return this.translateService.instant("INSTANCE.INSTANCE_CANCELED");
    } else if (instanceState === "completed") {
      return this.translateService.instant("INSTANCE.INSTANCE_COMPLETED");
    } else {
      return "";
    }
  }

  public sortThreadsByNewestDate(threads: Thread[]): Thread[] {
    return threads.sort((t1, t2) => {
      if (t1.messages.length !== 0 && t2.messages.length !== 0) {
        const t1LastUpdatedAt = t1.messages.at(-1).updatedAt;
        const t2LastUpdatedAt = t2.messages.at(-1).updatedAt;
        return this.compareNumber(t2LastUpdatedAt, t1LastUpdatedAt);
      } else if (t1.messages.length !== 0) {
        return -1;
      } else if (t2.messages.length !== 0) {
        return 1;
      } else {
        return this.compareNumber(t2.updatedAt, t1.updatedAt);
      }
    });
  }

  private compareNumber(x: number, y: number): number {
    if (x === y) {
      return 0;
    }
    return x > y ? 1 : -1;
  }

  // Expects format: '#rrggbb'
  brightenHexColor(color: string, percent: number) {
    const red = parseInt(color.slice(1, 3), 16);
    const green = parseInt(color.slice(3, 5), 16);
    const blue = parseInt(color.slice(5, 7), 16);
    const r = this.brightenColor(red, percent);
    const g = this.brightenColor(green, percent);
    const b = this.brightenColor(blue, percent);
    return "#" + r + g + b;
  }

  private brightenColor(color: number, percent: number): string {
    return (0 | ((1 << 8) + color + ((256 - color) * percent) / 100))
      .toString(16)
      .slice(1);
  }

  public isValidLanguage(language: string): language is Language {
    return LANGUAGES.includes(language as Language);
  }

  public getBrowserOrDefaultLanguage(): Language {
    const browserLanguage = this.translateService.getBrowserLang();
    const language = LANGUAGES.find((l) => browserLanguage.startsWith(l));
    return language ?? "en";
  }

  public getCurrentTimeTruncated(): number {
    return Math.round(new Date().getTime() / 1000);
  }

  public getAnswersheet(
    answersheets: Answersheet[],
    answersheetId: number,
  ): Answersheet | undefined {
    return answersheets.find((answersheet) => answersheet.id == answersheetId);
  }

  public getLatestUnfinishedAnswersheet(
    answersheets: Answersheet[],
    lessonId: number,
  ) {
    let result: Answersheet | undefined = undefined;
    for (const answersheet of answersheets) {
      if (
        answersheet.finished === 0 &&
        answersheet.lessonId == lessonId &&
        (result === undefined || answersheet.id > result.id)
      ) {
        result = answersheet;
      }
    }
    return result;
  }

  public getLatestAnswersheet(answersheets: Answersheet[], lessonId: number) {
    let result: Answersheet | undefined = undefined;
    for (const answersheet of answersheets) {
      if (
        answersheet.lessonId == lessonId &&
        (result === undefined || answersheet.id > result.id)
      ) {
        result = answersheet;
      }
    }
    return result;
  }
}
