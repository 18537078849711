<div class="question-media question" [class.banner]="model.banner">
  <div class="header-line" [class.banner-headline]="model.banner">
    <h4 class="ion-text-left">
      {{model.subtitleResolved}}
    </h4>
    <file-downloader [downloadUrl]="model.webUri" [clearFileName]="model.filename"></file-downloader>
  </div>
  <img *ngIf="MediaType.isImage(model.type)" [src]="model.webUri" alt="picture not available. Please try again." [style.width.%]="model.width">
  <!-- Use video player instead of html video if platform is android -->

  <div *ngIf="MediaType.isVideo(model.type)" class="ion-text-left">
    <video
      *ngIf="!model.isAudio"
      controls
      class="mediaplayer"
      [style.width.%]="model.width"
      crossorigin="anonymous"
      controlsList="nodownload"
    >
      <source [src]="model.webUri" type="video/mp4" />
      <track
        *ngIf="model.webUri && model.videoSubtitleUri"
        [src]="model.videoSubtitleUri"
        kind="subtitles"
        [label]="getSubtitlesLanguage(language)"
        [srclang]="language"
        default
      />
    </video>
    <audio *ngIf="model.isAudio" [src]="model.webUri" controls class="mediaplayer"></audio>
  </div>

  <div *ngIf="MediaType.isAudio(model.type)" class="audioDiv">
    <audio [src]="model.webUri" controls class="mediaplayer" controlsList="nodownload"></audio>
  </div>

  <file-downloader *ngIf="MediaType.isApplication(model.type) || MediaType.isWebVTT(model.type)" openAfterDownload="true" showAsHyperlink="true" [faIcon]="faIcon" [downloadUrl]="model.webUri" [clearFileName]="model.filename"></file-downloader>

  <div *ngIf='model.description != ""' [class.banner-headline]="model.banner">
    <p class="ion-text-left">
      {{model.descriptionResolved}}
    </p>
  </div>
</div>
